import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/how-prp-works.png";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'





const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PrpProPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#f7f7fb">
      <Container className="pt-lg-5 pb-lg-5">

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">PRP for hair loss Treatment Process</Itext>
</Iwrap>
      <Secondtext>
     Our Process

      </Secondtext>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 pl-0 pr-0 ml-0 ml-0 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="PRP for hair loss process" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
            <Text color="dark">
                The London PRP Hair/Beard Restoration Treatment has 3 steps, which is as follows: <br /> <br />

                1.	First, a small sample of your blood will be drawn from your arm and collected in a special tube. This tube is then placed in a device called a centrifuge.<br /> <br />
2.	Next, the blood sample is centrifuged. The centrifuge is used to separate the platelets from the whole blood , creating your own Platelet Rich Plasma. <br /><br />
3.	With the prepared syringes, the highly concentred PRP is extracted and ready to be strategically injected into certain areas of the scalp or face where the hair is thinning / hairless / balding. 

                </Text>
            
            
             <br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row> <br /> <br />

        <Row>
          <Col>
          <Secondtext>
            PRP Results
          </Secondtext>

          <Text color="dark">
                Platelet Rich Plasma (PRP)  for hair loss is a recent breakthrough with many extensive scientific and academic studies that supports and confirms the use of PRP injections as an effective form of cosmetic treatment to treat hair loss and help the regrowth and regeneration of hair follicles. <br /> <br />
                As with all cosmetic treatments, the ultimate success relies on the individuals biological and genetical characteristics, lifestyle factors, other causes of their hair loss, their hair texture and density.  <br /> <br />
                At Dermamina London, we will go through a thorough consultation before the treatment begins in order to create a personalised treatment plan tailored to your needs and suitability. 

                </Text>
          </Col>
        </Row>


      </Container>
    </Section>
  </>
);

export default PrpProPage;




import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import Link from 'gatsby-link';


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    
    margin: 0 auto;
    
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const VanpBeardPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-5 pb-lg-5 pt-3 pb-3">

          <Row>
              <Col>
      
      <Secondtext className="pb-4">
      Mesotherapy for Facial Rejuvenation:
      </Secondtext>
      <Text>
 
      Our Mesotherapy London skin treatment can be tailored to address a variety of skin concerns while nourishing, brightening and rejuvenating the skin, and stimulating the production of the skins own collagen and elastin, which improves the skins elasticity, adding a youthful look. <br /> <br />
 
 Mesotherapy not only improves dull, tired-looking skin and superficial wrinkles, but it also aids the body in flushing out ageing toxins by improving sluggish blood circulation. This treatment can also be used to address hyperpigmentation, acne, and give skin a glow from within. Skin will become more radiant, hydrated, nourished and firmer with an improved texture.<br /> <br />
 
      </Text>


      <Secondtext className="pb-4">
      Mesotherapy results 
      </Secondtext>
      <Text>
      Fine lines and wrinkles are encouraged to fade away for a more youthful, even skin tone, while dull or fatigued facial skin is rejuvenated for a more radiant, healthy appearance. You might expect a tighter, more contoured appearance in the treated area as your skin's metabolism rises. Some effects can be seen after just one treatment, but a course of up to three treatments at regular intervals is recommended, with maintenance sessions once every 6-12 months if needed.<br /> <br /> 
 
 Results last up to 3 months following mesotherapy session. For longer lasting results, course of treatments are recommended. First three sessions are advised to be done 4 weeks apart. <br /> <br />
 
      </Text>

      <Secondtext className="pb-4">
      Mesotherapy + Dermalux LED
      </Secondtext>
      <Text>
 
      Incorporate the multi award winning Dermalux LED Phototherapy to finish off your mesotherapy treatment for an extra boost to your skin. Your session can be boosted with one of the most powerful LED systems in the world, with clinically proven wavelengths. <br /> <br />

Dermalux LED Phototherapy instantly boosts the complexion to restore vitality and glow, through non-invasive therapeutic light energy that triggers the skins natural rejuvenation and repair processes – a perfect finish to achieving the best skin of your life. <Link to="/advanced-facial-treatments-london">Find out more</Link>
<br /> <br />
 
      </Text>


      <Secondtext className="pb-4">
      Ultimate Mesotherapy + Dermalux LED
      </Secondtext>
      <Text>
      Give your skin a boost of energy, radiance and ‘lit-from-within’ skin glow, with the ultimate Mesotherapy facial treatment. Following mesotherapy injections, chemical peel is applied to clear the superficial layer of the skin, helping the mesotherapy cocktail to penetrate deeper into the skin. It is a powerful combination that can deliver optimum results. <Link to="/advanced-facial-treatments-london">Find out more</Link> <br /> <br />
 
      </Text>


      
      <Secondtext className="pb-4">
      Mesotherapy Hair Growth 
      </Secondtext>
      <Text>
      Mesotherapy for hair growth targets specific areas of the scalp where the hair is thinning or balding. It’s unique combination of vitamins, minerals, and amino acids encourages and restores hair development in pre-existing hair follicles. It can aid in boosting microcirculation and supplying essential nutrients to hair follicles, which encourages the growth of hair and results in healthier, thicker, and fuller-looking hair. <Link to="/mesotherapy-hair">Find our more</Link> <br /> <br />
 
      </Text>
              </Col>
          </Row>
       
      </Container>
    </Section>
  </>
);

export default VanpBeardPage;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/beard2.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'




const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const Secondtext = styled.div`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const ConBeardPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section>
      <Container>

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">PRP Beard Restoration London</Itext>
</Iwrap>
      <Secondtext>
      What is PRP beard restoration treatment?

      </Secondtext>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="prp beard restoration" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

         
              
              Treat your beard hair using our unique, minimally invasive Platelet- Rich Plasma (PRP) hair treatment. This one-of-a-kind treatment harnesses the incredibly powerful, natural healing power of your body’s own PRP to stimulate your hair follicles, providing you with <b><Span color="#000000">fuller, healthier and thicker beard</Span></b>. <br /> <br />

A full thick beard is very desirable especially if you have gaps and unevenness in your beard, moustache and chin. PRP Beard Restoration not only stimulates healthy hair growth, but improves blood circulation, promotes tissue healing and the production of growth factors that lead to cell regeneration.






              </Text>
            
            
             <br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default ConBeardPage;
